import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, ButtonType, Popover, Tooltip } from '@junglescout/edna'

import { t } from 'services/i18n'
import { KEYWORD_RANK_DISTRIBUTION_TOOLTIPS } from 'constants/competitive_intelligence/common'
import { DistributionTopRanksPopover } from './DistributionTopRanksPopover'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

const DistributionPositionCell = ({ filter, label, row }) => {
  const key = row.original.name

  return (
    <Container>
      <Tooltip
        content={KEYWORD_RANK_DISTRIBUTION_TOOLTIPS[`top${key}`]}
        side="right"
        size="medium">
        <span>
          {t(
            'rank_tracker:KeywordRankDistributionTable.labels.top',
            'Top {{key}}',
            { key }
          )}
        </span>
      </Tooltip>
      <Popover
        content={
          <DistributionTopRanksPopover
            filter={filter}
            label={label}
            row={row}
          />
        }
        side="bottom"
        width="380px">
        <Button
          btnType={ButtonType.TERTIARY}
          data-testid="distribution-table-view-btn">
          {t('generic:view', 'View')}
        </Button>
      </Popover>
    </Container>
  )
}

DistributionPositionCell.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired
}

export { DistributionPositionCell }
