import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { EmptyState, Icon, Text, ToggleGroup } from '@junglescout/edna'
import { TYPOGRAPHY } from '@junglescout/styles-library'

import { t } from 'services/i18n'
import { RANK_TRACKER_DISTRIBUTION_POPOVER_FILTERS } from 'constants/competitive_intelligence/common'
import { JSPRO_BUCKET } from 'constants/s3'
import { humanizeLongNumberWithLocale } from 'helpers/formatters'
import { RankKeywordsTableContext } from 'components/common/shared_components/RankKeywords/RankKeywordsContext'

const Content = styled.aside`
  padding: 15px 8px;

  .distribution-empty-tooltip {
    padding: 5px;
    img {
      margin-bottom: 0;
    }
    [class*='Content'] {
      gap: 5px;
    }
    h2 {
      ${TYPOGRAPHY.headingSm}
    }
    p {
      ${TYPOGRAPHY.bodySm}
    }
  }
`
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px;
`
const Main = styled.main`
  max-height: 205px;
  overflow: auto;
`
const KeywordName = styled(Text)`
  width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const KeywordRow = styled.div`
  display: grid;
  grid-template-columns: 148px 94px 104px;
  grid-auto-rows: minmax(39px, auto);
  align-items: center;
  border-radius: 4px;
  padding: 0 9px;

  &:hover {
    background-color: ${props => props.theme.colors.grey050};
    cursor: pointer;

    [class*='Component']:first-of-type {
      color: ${props => props.theme.colors.primary};
    }
  }
`
const TrendContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
  ${TYPOGRAPHY.bodySm}
`
const TrendIndicator = styled.div`
  display: flex;
  ${props =>
    props.isUp
      ? `transform: rotate(0); color: ${props.theme.colors.green500};`
      : `transform: rotate(180deg); color: ${props.theme.colors.red500};`};
`
const TrendValue = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`

const TrendCell = ({ current, isUp, previous }) => {
  return (
    <TrendContainer>
      <TrendIndicator isUp={isUp}>
        <Icon name="CARET_UP" width="30px" height="30px" />
      </TrendIndicator>
      <TrendValue>
        {previous}
        <Icon name="ARROW_RIGHT" width="13px" height="13px" />
        {current}
      </TrendValue>
    </TrendContainer>
  )
}

const DistributionTopRanksPopover = ({ filter, label, row }) => {
  const [selectedToggle, setSelectedToggle] = useState('gained')
  const {
    totalLostChange,
    totalGainedChange,
    gainedKeywords,
    lostKeywords
  } = row.original

  const { tableQuery, setTableQuery } = useContext(RankKeywordsTableContext)

  const renderEmptyState = () => {
    return (
      <EmptyState
        asCard={false}
        className="distribution-empty-tooltip"
        header={t(
          'competitive_intelligence:KeywordAnalysis.PositionTracker.emptyState.header',
          'No change in the selected time period'
        )}
        image={`${JSPRO_BUCKET}/images/empty-state/folders.svg`}
        imageMaxHeight="48px"
        message={t(
          'competitive_intelligence:KeywordAnalysis.PositionTracker.emptyState.message',
          'It looks like there were no new keywords {{ filter }} during this time period. Please adjust your date range or try different filters to see other insights.',
          { filter: selectedToggle }
        )}
      />
    )
  }

  const handleKeywordSearch = keyword => {
    const newQuery = {
      ...tableQuery,
      query: keyword
    }
    setTableQuery(newQuery)
  }

  const getRankData = keyword => {
    switch (filter) {
      case 'allKeywords':
        return keyword.absoluteRank
      case 'organicOnly':
        return keyword.organicRank
      case 'sponsoredOnly':
        return keyword.sponsoredRank
      default:
        return keyword.absoluteRank
    }
  }

  const renderTable = keywords => {
    return keywords.map(keyword => {
      const { id, searchVolume } = keyword
      const keywordName = id?.split('/')[1]

      const { current, previous } = getRankData(keyword)

      return (
        <KeywordRow key={id} onClick={() => handleKeywordSearch(keywordName)}>
          <KeywordName title={keywordName}>{keywordName}</KeywordName>
          <TrendCell
            isUp={selectedToggle === 'gained'}
            current={current}
            previous={previous}
          />
          <Text align="right" padding="0 12px 0 0">
            {humanizeLongNumberWithLocale(searchVolume)}
          </Text>
        </KeywordRow>
      )
    })
  }

  const isGainedSelected = selectedToggle === 'gained'
  const isLostSelected = selectedToggle === 'lost'

  const isNoChanges =
    (isGainedSelected && !totalGainedChange) ||
    (isLostSelected && !totalLostChange)

  return (
    <Content data-testid="distribution-table-popover">
      <Header>
        <Text variant="headingSm" color="grey600">
          {label}{' '}
          {t(
            'competitive_intelligence:KeywordAnalysis.KeywordDistribution.change',
            'Change'
          )}
        </Text>
        <ToggleGroup
          handleClick={setSelectedToggle}
          value={selectedToggle}
          items={RANK_TRACKER_DISTRIBUTION_POPOVER_FILTERS({
            gained: totalGainedChange,
            lost: totalLostChange,
            selected: selectedToggle
          })}
        />
      </Header>
      <Main>
        {isNoChanges
          ? renderEmptyState()
          : renderTable(isGainedSelected ? gainedKeywords : lostKeywords)}
      </Main>
    </Content>
  )
}

DistributionTopRanksPopover.propTypes = {
  filter: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  row: PropTypes.objectOf(PropTypes.any).isRequired
}
export { DistributionTopRanksPopover }
